import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../../components/Common/Layout";
import CompanyLogo from "../../components/Website/TrustUs/CompanyLogo";
import ProjectsNavigation from "../../components/Website/ProjectsNavigation";
import ServicePellet from "../../components/Website/ServicePellet";

const GioMasterClass = ({ data }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const imagesData = data.allFile.edges.map(
    (edge) => edge.node.childImageSharp.fluid
  );

  return (
    <Layout
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
      selectedLanguage="en"
      title="Brocode Studio || Gio Masterclass"
      description="Gio Masterclass"
    >
      <div className="project-page">
        <div className="brocode-content">
          <Row className="bk-about-area">
            <Col md={5} xs={12}>
              <Fade bottom duration={2000}>
                <div className="content">
                  <div className="title wow move-up mb--10">
                    <h2>Gio Masterclass</h2>
                  </div>
                  <ServicePellet service="UI & UX" />
                  <ServicePellet service="Branding" />
                  <ServicePellet service="Development" />
                  <p class="wow move-up mb--20">
                    Website were users can purchase and watch masterclasses from
                    one of the world’s best performance coach.
                  </p>
                </div>
              </Fade>
            </Col>
            <Col md={7} xs={12}>
              <Img fluid={imagesData[5]} />
            </Col>
          </Row>
          <Fade bottom duration={2000}>
            <Row className="my-4">
              <Col md={10} xs={12}>
                <Img fluid={imagesData[0]} />
              </Col>
              <Col md={2} xs={12} className="d-none d-md-block">
                <Img fluid={imagesData[1]} />
              </Col>
            </Row>
          </Fade>
          <Fade bottom duration={2000}>
            <Row className="my-4">
              <Col xs={12} md={10} className="mx-auto">
                <Img fluid={imagesData[2]} />
              </Col>
            </Row>
          </Fade>
          <Fade bottom duration={2000}>
            <Row className="my-4">
              <Col md={10} xs={12}>
                <Img fluid={imagesData[3]} />
              </Col>
              <Col md={2} xs={12} className="d-none d-md-block">
                <Img fluid={imagesData[4]} />
              </Col>
            </Row>
          </Fade>
          <Fade bottom duration={2000}>
            <Row className="justify-content-center">
              <CompanyLogo name="gio" />
            </Row>
          </Fade>

          <ProjectsNavigation
            nextProject={{ name: "Vortex", link: "/work/vortex" }}
            prevProject={{
              name: "Árvore da Felicidade",
              link: "/work/arvoredafelicidade",
            }}
            selectedLanguage="en"
          />
        </div>
      </div>
    </Layout>
  );
};

export default GioMasterClass;

export const GioMasterClassQuery = graphql`
  query GioMasterClassPhotos {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
        dir: { regex: "/static/img/projects/gio/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1400, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
