import React from "react";

// our services icons imports
import branding from "../../../img/ourServices/branding.svg";
import dev from "../../../img/ourServices/dev.svg";
import mentoring from "../../../img/ourServices/mentoring.svg";
import mobileapps from "../../../img/ourServices/mobileapps.svg";
import SEO from "../../../img/ourServices/SEO.svg";
import uiux from "../../../img/ourServices/uiux.svg";

const broCodeServices = [
  {
    icon: uiux,
    title: "UI & UX Design",
  },
  {
    icon: uiux,
    title: "UI & UX",
  },
  {
    icon: dev,
    title: "Development",
  },
  {
    icon: mobileapps,
    title: "Mobile Apps",
  },
  {
    icon: branding,
    title: "Branding",
  },
  {
    icon: SEO,
    title: "SEO / Content Strategy",
  },
  {
    icon: mentoring,
    title: "Mentoring",
  },
];

function ServicePellet({ service }) {
  const serviceData = broCodeServices.find((el) => el.title === service);
  return (
    <div className="broCode-servicePellet">
      <span>
        <img src={serviceData.icon} alt={serviceData.title} />{" "}
        {serviceData.title}
      </span>
    </div>
  );
}

export default ServicePellet;
